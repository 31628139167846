<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-10-07 18:47 -->
<!-- Description:  -->
<template>
  <table class="table table-sm table-bordered">
    <thead>
      <tr>
        <th>Nº</th>
        <th>N. Cl.</th>
        <th>Apellidos y Nombres</th>
        <th>Tratamiento</th>
        <th>Pz</th>
        <th>Proc/Term</th>
        <th>Dr.</th>
        <th v-if="!noPrices">M.Pago</th>
        <th v-if="!noPrices">Monto</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(l, i) in list" :key="i">
        <td>{{ i + 1 }}</td>
        <td>{{ l.client_code }}</td>
        <td>{{ l.client_name }}</td>
        <td>
          <router-link :to="generateLinkToFollow(l)">
            {{ l.product_name }}
          </router-link>
        </td>
        <td>{{ l.piece }}</td>
        <td>{{ l.maked_date ? "si" : "no" }}</td>
        <td>{{ l.doctor }}</td>
        <td v-if="!noPrices" class="text-nowrap text-right">
          {{ l.pay_method }}
        </td>
        <td v-if="!noPrices" class="text-nowrap text-right">
          <app-span-money
            moneyCode="1"
            :quantity="l.amount_paid"
          ></app-span-money>
        </td>
      </tr>
      <tr v-if="!noPrices">
        <td colspan="7"></td>
        <th class="text-nowrap text-right">
          <app-span-money moneyCode="1" :quantity="total1()"></app-span-money>
        </th>
        <th class="text-nowrap text-right">
          <app-span-money moneyCode="1" :quantity="total2()"></app-span-money>
        </th>
      </tr>
    </tbody>
  </table>
</template>
<script>
// script content
const sumatoria = function(col) {
  return (p, c) => {
    return p + (c[col] ? parseFloat(c[col]) : 0);
  };
};
export default {
  name: "TableControlView",
  components: {
    //
  },

  // directives

  props: {
    list: { required: true },
    noPrices: { default: false },
  },

  data: () => ({
    total_1: 0,
    total_2: 0,
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    // this.total_1 = this.total1();
    // this.total_2 = this.total2();
  },

  methods: {
    total1() {
      return this.list.reduce(sumatoria("unit_price"), 0);
    },
    total2() {
      return this.list.reduce(sumatoria("amount_paid"), 0);
    },
    generateLinkToFollow(l) {
      if (l.treatment_plan_detail_id) {
        return `/dental-clinic/patients/${l.client_id}/treatment-plans/${l.treatment_plan_id}`;
      }
      return `/dental-clinic/patients/${l.client_id}/treatment-plans`;
    },
  },
};
</script>
<style scoped></style>
