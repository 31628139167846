<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-10-07 18:45 -->
<!-- Description:  -->
<template>
  <div>
    <h4>CONTROL POR RANGO</h4>
    <TableControlView :list="list"></TableControlView>
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
import TableControlView from "./TableControlView.vue";

// script content
export default {
  name: "ControlRange",
  components: {
    TableControlView,
  },

  // directives

  props: {
    //
  },

  data: () => ({
    list: [],
    data_ini: "",
    data_end: "",
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    // this.reload();
  },

  methods: {
    async reload() {
      DentalClinicService.getControlRange({
        date_ini: "2024-10-01",
        date_end: "2024-10-31",
      });
    },
    //
    //
  },
};
</script>
<style scoped></style>
